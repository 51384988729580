import React from 'react';
import * as GlobalStyles from '../GlobalStyles.js';
import Breakpoints from '../utils/Breakpoints';
import * as StyleSheet from '../utils/StyleSheet';
import useWindowDimensions from '../utils/useWindowDimensions';
import { Button, ScreenContainer, withTheme } from '@draftbit/ui';
import { Image } from 'react-native';

const BlankScreen = props => {
  const { theme } = props;
  const dimensions = useWindowDimensions();

  return (
    <ScreenContainer
      hasSafeArea={false}
      scrollable={false}
      style={StyleSheet.applyWidth(
        {
          alignItems: 'center',
          backgroundColor: theme.colors['Primary'],
          justifyContent: 'space-evenly',
        },
        dimensions.width
      )}
    >
      <Image
        resizeMode={'cover'}
        source={{
          uri: 'https://static.draftbit.com/images/placeholder-image.png',
        }}
        style={StyleSheet.applyWidth(
          GlobalStyles.ImageStyles(theme)['Image'],
          dimensions.width
        )}
      />
      <Button
        style={StyleSheet.applyWidth(
          StyleSheet.compose(GlobalStyles.ButtonStyles(theme)['Button'], {
            backgroundColor: theme.colors['Error'],
          }),
          dimensions.width
        )}
        title={'Go To Draftbit ->'}
      />
    </ScreenContainer>
  );
};

export default withTheme(BlankScreen);
